import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

const StayIcon = props => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}, props), React.createElement("path", {
  d: "M24 2.571h-3.429v.858C20.575 4.886 19.423 6.038 18 6c-1.418.038-2.566-1.114-2.571-2.571V2.57H8.57v.858C8.573 4.886 7.421 6.038 6 6c-1.418.038-2.57-1.114-2.571-2.571V2.57H0V24h24V2.571m-1.714 19.715H1.714V8.57h20.572v13.715M18 5.143a1.704 1.704 0 0 1-1.714-1.714V1.714C16.286.754 17.05 0 18 0c.948 0 1.714.754 1.714 1.714V3.43c0 .962-.767 1.714-1.714 1.714m-12 0c-.95 0-1.714-.752-1.714-1.714V1.714C4.286.754 5.05 0 6 0c.946 0 1.714.753 1.714 1.714V3.43c0 .962-.768 1.714-1.714 1.714m.857 7.714H4.286v-2.571h2.571v2.571m4.286 0H8.57v-2.571h2.572v2.571m4.286 0h-2.572v-2.571h2.572v2.571m4.285 0h-2.571v-2.571h2.571v2.571m-8.571 4.286H8.57V14.57h2.572v2.572m4.286 0h-2.572V14.57h2.572v2.572m4.285 0h-2.571V14.57h2.571v2.572m-8.571 4.286H8.57v-2.572h2.572v2.572m-4.286-4.286H4.286V14.57h2.571v2.572m0 4.286H4.286v-2.572h2.571v2.572z",
  fillRule: "evenodd"
}));

export default StayIcon;