import * as React from "react";
import { useEffect, useState, lazy, Suspense } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { withConfiguration } from "msem-standard-products/es/services/config";
import ChevronLeftIcon from "msem-lib/es/icons/chevron-left";
import CloseIcon from "msem-lib/es/icons/close";
import StayIcon from "msem-lib/es/icons/stay";
import css from "./widget-standard-products.module.css";

const ScreenMerchants = lazy(() =>
  import(/* webpackChunkName: "otherProduct-merchants" */ "msem-standard-products/es/components/screen-merchants")
);
const ScreenMerchant = lazy(() =>
  import(/* webpackChunkName: "otherProduct-merchant" */ "msem-standard-products/es/components/screen-merchant")
);
const ScreenProducts = lazy(() =>
  import(/* webpackChunkName: "otherProduct-products" */ "msem-standard-products/es/components/screen-products")
);

const SCREENS = [ScreenMerchants, ScreenMerchant, ScreenProducts];
const DEFAULT_STEP = 0;

const OpenStay = ({ stay, openStay }) => {
  const intl = useIntl();
  const from = new Date(stay.from);
  const to = new Date(stay.to);
  return (
    <div className={css.openStay} onClick={openStay}>
      <div className={css.stayDates}>
        <div>{intl.formatDate(from)}</div>
        <div>{intl.formatDate(to)}</div>
      </div>
      <div className={css.stayIcon}>
        <StayIcon className={css.stayIconSvg} />
      </div>
    </div>
  );
};

const Widget = ({
  stay,
  date,
  merchantSlug,
  lang,
  cartChanged,
  close,
  inside,
  facet,
  resort,
  categoryGroup,
  hoteId,
  preview,
  callback,
  cartUrl,
  channel
}) => {
  const [currentStay, setCurrentStay] = useState({ from: new Date(stay.from), to: new Date(stay.to) });
  const [dateSelected, setDateSelected] = useState(date ? new Date(date) : new Date(stay.from));
  const [screenSelected, setScreenSelected] = useState(DEFAULT_STEP);
  const [productScreenSelected, setProductScreenSelected] = useState(0);
  const [merchant, setMerchant] = useState();
  const [category, setCategory] = useState();
  const [product, setProduct] = useState();

  useEffect(() => {
    if (merchantSlug) {
      merchantClicked(merchantSlug);
    }
  }, [merchantSlug]);

  const merchantClicked = (merchantSlug) => {
    return {
      onClick: () => {
        setMerchant(merchantSlug);
        setScreenSelected(1);
      }
    };
  };

  const categoryClicked = (category) => {
    return {
      onClick: () => {
        setCategory(category);
        setScreenSelected(2);
      }
    };
  };

  const productClicked = (product) => {
    setProduct(product);
    setProductScreenSelected(1);
  };

  const previousScreen = () => {
    if (productScreenSelected === 0) {
      const previousScreenSelected = Math.max(0, screenSelected - 1);
      const SelectedMerchant = screenSelected < 1 ? undefined : merchant;
      const selectedCategory = screenSelected < 2 ? undefined : category;
      setScreenSelected(previousScreenSelected);
      setMerchant(SelectedMerchant);
      setCategory(selectedCategory);
    } else {
      setProductScreenSelected(0);
      setProduct(undefined);
    }
  };

  const resetState = (stay) => {
    setCurrentStay({ from: new Date(stay.from), to: new Date(stay.to) });
    setDateSelected(new Date(stay.from));
    setProductScreenSelected(0);
    setScreenSelected(0);
    setCategory(undefined);
    setMerchant(undefined);
    setProduct(undefined);
  };

  const openStay = async () => {
    const stay = await window.openStay({ lang, cartChanged });
    if (stay.changed) {
      resetState(stay);
    }
  };

  const renderBack = () => {
    if (screenSelected > 0) {
      return (
        <div className={css.back} onClick={previousScreen}>
          <ChevronLeftIcon />
        </div>
      );
    }
  };

  const Screen = SCREENS[screenSelected];
  const imageURL = `/images/${lang}/MSEM.svg`;
  const widgetClasses = classnames(css.widgetStandardProduct, { [css.inside]: inside });

  return withConfiguration(({ resourceUrl }) => (
    <div className={widgetClasses}>
      <div className={css.bar}>
        {renderBack()}
        <img className={css.logo} alt="" src={`${resourceUrl}${imageURL}`} />
        <div className={css.rightBlock}>
          <OpenStay stay={currentStay} openStay={openStay} />
          {close !== undefined && close !== null && !inside && (
            <div className={css.close} onClick={close}>
              <CloseIcon className={css.closeSvg} />
            </div>
          )}
        </div>
      </div>
      <div className={css.container}>
        <Suspense fallback="Loading">
          <Screen
            resort={resort}
            lang={lang}
            facet={facet}
            preview={preview}
            stay={currentStay}
            dateSelected={dateSelected}
            hoteId={hoteId}
            categoryGroup={categoryGroup}
            productScreenSelected={productScreenSelected}
            merchantClicked={merchantClicked}
            categoryClicked={categoryClicked}
            productClicked={productClicked}
            previousScreen={previousScreen}
            callback={callback}
            cartChanged={cartChanged}
            cartUrl={cartUrl}
            close={close}
            channel={channel}
            merchant={merchant}
            category={category}
            product={product}
            screenSelected={screenSelected}
          />
        </Suspense>
      </div>
    </div>
  ));
};

export default Widget;
