import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

const CloseIcon = props => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}, props), React.createElement("path", {
  d: "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
}));

export default CloseIcon;