import _extends from "@babel/runtime/helpers/extends";
import * as React from "react";

const ChevronLeftIcon = props => React.createElement("svg", _extends({
  width: "24",
  height: "24",
  viewBox: "0 0 24 24"
}, props), React.createElement("path", {
  d: "M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
}), React.createElement("path", {
  d: "M0 0h24v24H0z",
  fill: "none"
}));

export default ChevronLeftIcon;