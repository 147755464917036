import "core-js/modules/es.object.assign";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { IntlProvider } from "react-intl";
import { withConfiguration } from "msem-standard-products/es/services/config";
import { loadJS } from "msem-lib/es/services/loader";
import fr from "msem-standard-products/es/locales/fr.json";
import en from "msem-standard-products/es/locales/en.json";
import Widget from "./widget-standard-products";
import "msem-lib/es/theme.css";

const CONTAINER_ID = "standard-products-selector";

const originalBodyOverflow = document.body.style.overflow;

const close = () => {
  document.body.style.overflow = originalBodyOverflow;
  ReactDOM.unmountComponentAtNode(document.getElementById(CONTAINER_ID));
};

const getContainer = () => {
  const container = document.getElementById(CONTAINER_ID);
  return container === null
    ? document.body.appendChild(Object.assign(document.createElement("div"), { id: CONTAINER_ID }))
    : container;
};

window.openStandardProducts = async ({
  resort,
  inside,
  lang = "fr",
  facet = 0,
  preview = false,
  categoryGroup,
  hoteId,
  merchantSlug,
  date,
  cartChanged,
  cartUrl,
  stayChanged
}) => {
  const stayUrl = withConfiguration((config) => config.stayUrl);
  await loadJS({
    id: "msem-stay-selector-script",
    url: `${stayUrl}/static/js/msem-widget-stay.js`
  });
  const stay = await window.getStay({ lang, cartChanged });
  const messages = lang === "fr" ? fr : en;
  if (!inside) {
    document.body.style.overflow = "hidden";
  }
  ReactDOM.render(
    <IntlProvider locale={lang} messages={messages}>
      <Widget
        resort={resort}
        inside={inside}
        lang={lang}
        facet={facet}
        preview={preview}
        hoteId={hoteId}
        categoryGroup={categoryGroup}
        merchantSlug={merchantSlug}
        close={close}
        stay={stay}
        date={date}
        stayChanged={stayChanged}
        cartChanged={cartChanged}
        cartUrl={cartUrl}
      />
    </IntlProvider>,
    getContainer()
  );
};
